//const BusinessObject = require('./BusinessObject');
import { json } from 'body-parser';
import BusinessObject from './BusinessObject';
import { themeSelectors } from '../../state/redux/theme';
class Asset_1_0_0 extends BusinessObject {
  constructor(tags, jsonContent, txID, txTimeStamp) {
    const requiredProperties = ['name', 'batch'];

    // for (const prop of requiredProperties) {
    //   if (!(prop in jsonContent) || jsonContent[prop] === null || jsonContent[prop] === undefined) {
    //     throw new Error(`Missing or invalid value for required property: ${prop}`);
    //   }
    // }

    super(txTimeStamp);
    this.tags = tags;
    this.txID = txID;
    this.txTimeStamp = txTimeStamp;
    this.type = this.getType();
    this.version = this.getVersion();
    try {
      // this.productionID = jsonContent.productionID;
      // this.id = jsonContent.id;
      // this.name = jsonContent.name;
      // this.batchID = jsonContent.batchID;
      // this.location = jsonContent.location;
      // this.timestamp = jsonContent.timestamp;
      // this.origin = jsonContent.origin;
      // this.size = jsonContent.size;
      // this.variety = jsonContent.variety;
      // this.producer = jsonContent.producer;




      this.batch = jsonContent.batch;
      this.name = jsonContent.name;
      this.variety = jsonContent.variety;

      // packages
      this.producer = jsonContent.producer;
      this.producerCode = jsonContent.producerCode;
      this.quantity = jsonContent.quantity;

      this.unit = jsonContent.unit;
      // this.harvestDate = jsonContent.harvestDate;
      this.packagingDate = jsonContent.packagingDate;

      this.inputBatch =  jsonContent.inputBatch;
      // this.date = jsonContent.date;
      this.id = jsonContent.id;
      this.timestamp = jsonContent.timestamp;

    } catch (err) {
      throw new Error('Asset not well-formed:' + err);
    }
  }

  // Use the generic toJsonString method from BusinessObject
  toJsonString() {
    // Create an object based on the desired fields
    const jsonObject = {
      // productionID: this.productionID,
      // id: this.id,
      // name: this.name,
      // batchID: this.batchID,
      // location: this.location,
      // timestamp: this.timestamp,
      // type: this.getType(),
      // version: this.getVersion(),
      // variety: this.variety,
      // origin: this.origin,
      
      // producer: this.producer,


      // Add more fields as needed
    
      batch: this.batch,
      name: this.name,
      variety: this.variety,
      varietyCode: this.varietyCode,

      // packages
      producer: this.producer,
      producerCode: this.producerCode,
      quantity: this.quantity,
      unit: this.unit,

      date: this.date,
      
      // harvestDate: this.harvestDate,
      packagingDate: this.packagingDate,
      id: this.id,
      timestamp: this.timestamp,
    

      type: this.getType(),
      version: this.getVersion(),
    
    };

    // Use the generic toJsonString method
    return JSON.stringify(jsonObject, null, 2);
  }

  getType() {
    return "Asset";
  }

  getVersion() {
    return "1.0.0";
  }

  getTags() {
    return this.tags;
  }
}

//module.exports = Asset_1_0_0;
 
export default Asset_1_0_0;