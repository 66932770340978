import { search } from '../../trace-rice/api/api.js';
import Asset_1_0_0 from './Asset_1_0_0';
import Activity_1_0_0 from './Activity_1_0_0';
import Event_1_0_0 from './Event_1_0_0';
import { readAndParseJson } from '../../trace-rice/utils/utils.js';  // Adjust the import path based on your project structure
import { NotificationManager } from 'react-notifications';

const fs = require('fs');

class ClassFactory {
  create(type, version, tags, jsonContent, txID, txTimeStamp) {
    switch (type) {
      case 'Asset':
        switch (version) {
          case '1.0.0':

            return new Asset_1_0_0(tags, jsonContent, txID, txTimeStamp);
          default:
            throw new Error('Invalid version for Asset');
        }
      case 'Activity':
        switch (version) {
          case '1.0.0':
            return new Activity_1_0_0(tags, jsonContent, txID, txTimeStamp);
          default:
            throw new Error('Invalid version for Activity');
        }
      case 'Event':
        switch (version) {
          case '1.0.0':
            return new Event_1_0_0(tags, jsonContent, txID, txTimeStamp);
          default:
            throw new Error('Invalid version for Event');
        }
      default:
        throw new Error('Invalid type');
    }
  }

  sortBusinessObjectsByTimeStamp(businessObjects) {
    return businessObjects.sort((a, b) => {
      const aDate = new Date(a.txTimeStamp || 0).getTime();
      const bDate = new Date(b.txTimeStamp || 0).getTime();
      return aDate - bDate;
    });
  }

  sortBusinessActivities(businessObjects) {
    const activities = businessObjects
      .filter(obj => obj instanceof Activity_1_0_0)
      .sort((a, b) => a.activityCode.localeCompare(b.activityCode));

    return activities;
  }
  getUnique(businessObjects) {

    const sortedObjects = [];
    const assets = businessObjects.filter(obj => obj instanceof Asset_1_0_0);
    const activities = this.sortBusinessActivities(businessObjects);
    const events = businessObjects.filter(obj => obj instanceof Event_1_0_0);
    const uniqueIds = new Set();


    function addToSortedObjects(obj) {
      let id;


      if (obj instanceof Activity_1_0_0) {
        id = `activity:${obj.id}`;
      } else if (obj instanceof Event_1_0_0) {
        id = `event:${obj.processInstance}`;
      } else if (obj instanceof Asset_1_0_0) {
        id = `asset:${obj.id}`;
      } else {
        id = 'unknown';
      }


      //console.log(id);
      if (!uniqueIds.has(id)) {
        sortedObjects.push(obj);
        uniqueIds.add(id);
      }
    }
    businessObjects.forEach(obj => {

      //console.log("adding")
      addToSortedObjects(obj);
    });
    return sortedObjects;
  }
  sortBusinessObjects(businessObjects) {

    console.log("sortBusinessObjects", businessObjects);
    const sortedObjects = [];
    const assets = businessObjects.filter(obj => obj instanceof Asset_1_0_0);
    const activities = this.sortBusinessActivities(businessObjects);
    const events = businessObjects.filter(obj => obj instanceof Event_1_0_0);
    const uniqueIds = new Set();

    function addToSortedObjects(obj) {
      let id;



      if (obj instanceof Activity_1_0_0) {
        id = `activity:${obj.id}`;
      } else if (obj instanceof Event_1_0_0) {
        id = `event:${obj.processInstance}`;
      } else if (obj instanceof Asset_1_0_0) {
        id = `asset:${obj.id}`;
      } else {
        id = 'unknown';
      }

      if (!uniqueIds.has(id)) {
        sortedObjects.push(obj);
        uniqueIds.add(id);
      }
    }

    activities.forEach(activity => {
      const inputAsset = assets.find(asset => asset.batch === activity.input);

      if (inputAsset) {
        addToSortedObjects(inputAsset);
      }

      const predecessorActivity = activities.find(a => a.id === activity.predecessorID);
      if (predecessorActivity) {
        const index = sortedObjects.indexOf(predecessorActivity);
        sortedObjects.splice(index + 2, 0, activity);
      } else {
        addToSortedObjects(activity);
      }

      // const outpuAsset = assets.find(asset => asset.batch === activity.output);

      // if (outpuAsset) {
      //   addToSortedObjects(outpuAsset);
      // }


      // const outpuAsset2 = assets.find(asset => asset.outputBatch === activity.output);

      // if (outpuAsset2) {
      //   addToSortedObjects(outpuAsset2);
      // }


      const outpuAsset3 = assets.find(asset => asset.outputBatch === activity.inputPackaging);

      if (outpuAsset3) {
        addToSortedObjects(outpuAsset3);
      }
      const event = events.find(event => event.processInstance === activity.id);

      if (event) {
        addToSortedObjects(event);
      }
    });


    const sortedData = [...sortedObjects].sort((a, b) => a.id - b.id);

    return sortedData;
  }



  // async findMissingAndGapActivityCodes(sortedObjects) {
  //   let missingAndGapCodes = [];

  //   try {
  //     //const functionsWithActivities = await readAndParseJson();
  //     const functionsWithActivities = await readAndParseJson();
  //     const expectedActivityCodes = functionsWithActivities.reduce(
  //       (codes, func) => codes.concat(func.activities.map(activity => activity.code)),
  //       []
  //     );

  //     const sortedActivities = sortedObjects
  //       .filter(object => object instanceof Activity_1_0_0)
  //       .sort((a, b) => a.activityCode.localeCompare(b.activityCode));

  //     for (let i = 1; i < sortedActivities.length; i++) {
  //       const currentActivityCode = sortedActivities[i]?.activityCode;
  //       const previousActivityCode = sortedActivities[i - 1]?.activityCode;

  //       if (currentActivityCode && previousActivityCode) {
  //         const currentIndex = expectedActivityCodes.indexOf(currentActivityCode);
  //         const previousIndex = expectedActivityCodes.indexOf(previousActivityCode);

  //         if (currentIndex !== previousIndex + 1) {
  //           missingAndGapCodes.push(`Gap found between ${previousActivityCode} and ${currentActivityCode}`);
  //         }
  //       }
  //     }

  //     for (const expectedCode of expectedActivityCodes) {
  //       const found = sortedActivities.some(activity => activity.activityCode === expectedCode);

  //       if (!found) {
  //         missingAndGapCodes.push(`Activity code ${expectedCode} is missing.`);
  //       }
  //     }
  //   } catch (error) {
  //     console.error(`Error: ${error}`);
  //     throw new Error(`Error: ${error}`);
  //   }

  //   return missingAndGapCodes;
  // }
  async findMissingAndGapActivityCodes(sortedObjects) {
    let missingAndGapCodes = [];

    try {
      const functionsWithActivities = await readAndParseJson();
      const activities = functionsWithActivities.flatMap(func => func.activities);

      // Collect all unique input and output batch IDs from activities

      const sortedAssets = sortedObjects
        .filter(object => object instanceof Asset_1_0_0);

      const assetBatchIDs = [...new Set(sortedAssets.map(asset => asset.batch))];


      // const inputBatchIDs = [...new Set(activities.map(activity => activity.input))];
      // const outputBatchIDs = [...new Set(activities.map(activity => activity.output))];


      console.log("activities", activities)


      const sortedActivities = sortedObjects
        .filter(object => object instanceof Activity_1_0_0)
        .sort((a, b) => a.activityCode.localeCompare(b.activityCode));


      console.log("sortedActivities", sortedActivities)
      for (let i = 1; i < sortedActivities.length; i++) {
        const currentActivity = sortedActivities[i];
        const previousActivity = sortedActivities[i - 1];


        console.log("currentActivity", currentActivity)




        // Check for gaps in activity codes
        const currentIndex = activities.findIndex(activity => activity.code === currentActivity.activityCode);
        const previousIndex = activities.findIndex(activity => activity.code === previousActivity.activityCode);
        console.log(currentIndex, previousIndex)
        if (currentIndex !== previousIndex + 1) {
          missingAndGapCodes.push(`Gaps found between ${previousActivity.activityCode} and ${currentActivity.activityCode}`);
        }



        // Check for missing input batch ID
        if (!assetBatchIDs.includes(currentActivity.input)) {
          missingAndGapCodes.push(`Input batch ID ${currentActivity.input} for activity ${currentActivity.activityCode} is missing.`);
        }

        // Check for missing output batch ID
        if (!assetBatchIDs.includes(previousActivity.output)) {
          missingAndGapCodes.push(`Output batch ID ${previousActivity.output} for activity ${previousActivity.activityCode} is missing.`);
        }
      }

      // Continue with the existing gap and missing activity code validation...

    } catch (error) {
      console.error(`Error: ${error}`);
      throw new Error(`Error: ${error}`);
    }

    return missingAndGapCodes;
  }
  async findMissingGaps(sortedObjects) {
    let missingAndGapCodes = [];

    try {
      const processes = await readAndParseJson();

      // Use the last process directly from the processes array
      const lastProcess = processes[processes.length - 1];

      // Collect all unique input and output batch IDs from activities
      const sortedAssets = sortedObjects.filter(object => object instanceof Asset_1_0_0);
      const assetBatchIDs = [...new Set(sortedAssets.map(asset => asset.batch))];

      const act = sortedObjects
        .filter(object => object instanceof Activity_1_0_0)
        .sort((a, b) => a.activityCode.localeCompare(b.activityCode));

      // Get all activities in the last process
      const allActivities = lastProcess.functions.flatMap(func => func.activities);
      const allActivityCodes = allActivities.map(activity => activity.code);

      // only get activities that belong to the specific process
      const sortedActivities = act.filter(activity => allActivityCodes.includes(activity.activityCode));

      //console.log("sortedActivities", sortedActivities);

      for (let i = 1; i < sortedActivities.length; i++) {
        const currentActivity = sortedActivities[i];
        const previousActivity = sortedActivities[i - 1];

        // Check for gaps in activity codes
        let currentIndex = allActivityCodes.indexOf(currentActivity.activityCode);
        let previousIndex = allActivityCodes.indexOf(previousActivity.activityCode);

        // console.log("currentActivity", currentActivity);
        // console.log("previousActivity", previousActivity);
        // console.log("currentIndex", currentIndex);
        // console.log("previousIndex", previousIndex);

        if (currentIndex !== previousIndex + 1) {
          missingAndGapCodes.push(`Gaps found between ${previousActivity.activityCode} and ${currentActivity.activityCode}`);
        }

        // Check for missing input batch ID
        if (!assetBatchIDs.includes(currentActivity.input)) {
          missingAndGapCodes.push(`Input batch ID ${currentActivity.input} for activity ${currentActivity.activityCode} is missing.`);
        }

        // Check for missing output batch ID
        if (!assetBatchIDs.includes(previousActivity.output)) {
          missingAndGapCodes.push(`Output batch ID ${previousActivity.output} for activity ${previousActivity.activityCode} is missing.`);
        }
      }

      // Check for activities in allActivities that are missing in sortedActivities
      const missingActivities = allActivities.filter(activity => !sortedActivities.some(sortedActivity => sortedActivity.activityCode === activity.code));

      missingActivities.forEach(activity => {
        missingAndGapCodes.push(`Activity ${activity.code} is missing.`);
      });

      // Continue with the existing gap and missing activity code validation...
    } catch (error) {
      console.error(`Error: ${error}`);
      // throw new Error(`Error: ${error}`);

      return "Error: " + error;

    }

    return missingAndGapCodes;
  }


  // inferProcess(processes, activityCode, lastprocess) {

  //   if (lastprocess != null) {
  //     //console.log(lastprocess.functions)
  //     for (const func of lastprocess.functions) {

  //       for (const activity of func.activities) {
  //         if (activity.code === activityCode) {
  //           return lastprocess;
  //         }
  //       }
  //     }
  //   }
  //   else {
  //     for (const process of processes) {
  //       for (const func of process.functions) {
  //         for (const activity of func.activities) {
  //           if (activity.code === activityCode) {
  //             return process;
  //           }
  //         }
  //       }
  //     }
  //   }
  //   return null; // Process not inferred
  // }


  searchDownstream = async (batchId, traceableObjectTypes, visitedObjects = new Set()) => {
    try {
      const objects = await search(batchId);
      if (objects.toString().toLowerCase().startsWith("error")) {
        NotificationManager.removeAll();
        NotificationManager.error(objects, 'Error');

        return [];
        // return objects.toString();
      }

      let businessObjects = [];

      for (const obj of objects) {
        let type = obj.type.toLowerCase();
        const content = JSON.parse(obj.jsonContent);
        const data = this.create(obj.type, obj.schemaVersion, obj.tags, content, obj.txID, obj.txTimeStamp);

        const objectId = `${obj.type.toLowerCase()}-${obj.id}`;

        if (visitedObjects.has(objectId)) {
          continue; // Skip processing if already visited
        }

        visitedObjects.add(objectId);

        if (type === 'asset' && traceableObjectTypes.asset) {
          const asset = data;
          const downstreamBatchId = asset.batch;

          //console.log('Downstream asset batchId:', downstreamBatchId);

          if (downstreamBatchId && downstreamBatchId !== batchId) {
            const downstreamObjects = await this.searchDownstream(downstreamBatchId, traceableObjectTypes, visitedObjects);
            businessObjects = businessObjects.concat(downstreamObjects);
          }
          businessObjects.push(data);
        } else if (type === 'activity' && traceableObjectTypes.activity) {
          const asset = data;
          const downstreamBatchId = asset.output;

          if (downstreamBatchId && downstreamBatchId !== batchId) {
            const downstreamObjects = await this.searchDownstream(downstreamBatchId, traceableObjectTypes, visitedObjects);
            businessObjects = businessObjects.concat(downstreamObjects);
          }

          businessObjects.push(data);
        } else if (type === 'event' && traceableObjectTypes.event) {
          const asset = data;
          const downstreamBatchId = asset.batchID;
          // console.log ("asset.batchID ", asset.batchID )  
          // console.log ("batchId ", batchId )  

          if (downstreamBatchId && downstreamBatchId !== batchId) {
            const downstreamObjects = await this.searchDownstream(downstreamBatchId, traceableObjectTypes, visitedObjects);
            businessObjects = businessObjects.concat(downstreamObjects);
          }

          businessObjects.push(data);

          // console.log("busineess" , businessObjects);
        }
      }

      return businessObjects;
    } catch (error) {
      console.error('Error searching downstream:', error);
      //throw error;

      return "Error: " + error;

    }
  };

  searchUpstream = async (batchId, traceableObjectTypes, visitedObjects = new Set()) => {
    try {

      const objects = await search(batchId);

      //console.log("objects", objects);
      if (objects.toString().toLowerCase().startsWith("error")) {
        NotificationManager.removeAll();
        //NotificationManager.error(objects, 'Error');
        // return objects;
        return [];
      }

      let businessObjects = [];

      for (const obj of objects) {
        let type = obj.type.toLowerCase();
        const content = JSON.parse(obj.jsonContent);
        const data = this.create(obj.type, obj.schemaVersion, obj.tags, content, obj.txID, obj.txTimeStamp);

        const objectId = `${obj.type.toLowerCase()}-${obj.id}`;

        if (visitedObjects.has(objectId)) {
          continue; // Skip processing if already visited
        }

        visitedObjects.add(objectId);

        if (type === 'asset' && traceableObjectTypes.asset) {
          const asset = data;
          const upstreamBatchId = asset.batch;


          // console.log("asset upstreamBatchId", upstreamBatchId);

          // console.log("asset batchId", batchId);

          if (upstreamBatchId && upstreamBatchId !== batchId) {
            const upstreamObjects = await this.searchUpstream(upstreamBatchId, traceableObjectTypes, visitedObjects);
            businessObjects = businessObjects.concat(upstreamObjects);
          }
          businessObjects.push(data);



          //console.log("inputBatch*************", asset.inputBatch);
          if (asset.inputBatch != undefined && asset.inputBatch != null && asset.inputBatch != "") {


            const upstreamBatchIdTags = asset.inputBatch;



            // console.log("asset upstreamBatchIdTags", upstreamBatchIdTags);

            // console.log("asset upstreamBatchIdTags", batchId);

            if (upstreamBatchIdTags && upstreamBatchIdTags !== batchId) {
              const upstreamObjects = await this.searchUpstream(upstreamBatchIdTags, traceableObjectTypes, visitedObjects);
              businessObjects = businessObjects.concat(upstreamObjects);
            }
            businessObjects.push(data);
          }

          else if (asset.outputBatch != undefined && asset.outputBatch != null && asset.outputBatch != "") {


            const upstreamBatchIdTags = asset.outputBatch;



            // console.log("asset upstreamBatchIdTags", upstreamBatchIdTags);

            // console.log("asset upstreamBatchIdTags", batchId);

            if (upstreamBatchIdTags && upstreamBatchIdTags !== batchId) {
              const upstreamObjects = await this.searchUpstream(upstreamBatchIdTags, traceableObjectTypes, visitedObjects);
              businessObjects = businessObjects.concat(upstreamObjects);
            }
            businessObjects.push(data);
          }


        } else if (type === 'activity' && traceableObjectTypes.activity) {
          const asset = data;
          const upstreamBatchId = asset.input;


          // console.log("upstreamBatchId", upstreamBatchId);

          // console.log("batchId", batchId);


          if (upstreamBatchId && upstreamBatchId !== batchId) {
            const upstreamObjects = await this.searchUpstream(upstreamBatchId, traceableObjectTypes, visitedObjects);
            businessObjects = businessObjects.concat(upstreamObjects);
          }

          businessObjects.push(data);



          // add here for packagind 

          const upstreamBatchIdP = asset.inputPackaging;


          console.log("upstreamBatchIdP", upstreamBatchIdP);

          console.log("batchId", batchId);


          if (upstreamBatchIdP && upstreamBatchIdP !== batchId) {
            const upstreamObjects = await this.searchUpstream(upstreamBatchIdP, traceableObjectTypes, visitedObjects);
            businessObjects = businessObjects.concat(upstreamObjects);
          }

          businessObjects.push(data);



        } else if (type === 'event' && traceableObjectTypes.event) {
          const asset = data;
          const upstreamBatchId = asset.batch || '';


          if (upstreamBatchId && upstreamBatchId !== batchId) {
            const upstreamObjects = await this.searchUpstream(upstreamBatchId, traceableObjectTypes, visitedObjects);
            businessObjects = businessObjects.concat(upstreamObjects);
          }

          businessObjects.push(data);
        }
      }

      return businessObjects;
    } catch (error) {
      console.error('Error searching upstream:', error);
      return "Error: " + error;
    }
  };


  searchData = async (batchId, traceableObjectTypes, visitedObjects = new Set()) => {
    try {
      const objects = await search(batchId);

      if (objects.toString().toLowerCase().startsWith("error")) {
        NotificationManager.removeAll();
        NotificationManager.error(objects, 'Error');
        return objects;
        return [];
      }

      let businessObjects = [];

      for (const obj of objects) {
        let type = obj.type.toLowerCase();
        const content = JSON.parse(obj.jsonContent);
        const data = this.create(obj.type, obj.schemaVersion, obj.tags, content, obj.txID, obj.txTimeStamp);

        const objectId = `${obj.type.toLowerCase()}-${obj.id}`;

        if (visitedObjects.has(objectId)) {
          continue; // Skip processing if already visited
        }

        visitedObjects.add(objectId);

        if (type === 'asset' && traceableObjectTypes.asset) {

          businessObjects.push(data);
        } else if (type === 'activity' && traceableObjectTypes.activity) {

          businessObjects.push(data);
        } else if (type === 'event' && traceableObjectTypes.event) {


          businessObjects.push(data);
        }
      }

      return businessObjects;
    } catch (error) {
      console.error('Error searching upstream:', error);
      return "Error: " + error;
    }
  };

}

export default ClassFactory;