//const BusinessObject = require('./BusinessObject');
import BusinessObject from './BusinessObject';
class Activity_1_0_0 extends BusinessObject {
  constructor(tags, jsonContent, txID, txTimeStamp) {
    const requiredProperties = [
      'id', 'name', 'activityCode',
    ];

    // for (const prop of requiredProperties) {
    //   if (!(prop in jsonContent) || jsonContent[prop] === null || jsonContent[prop] === undefined) {
    //     throw new Error(`Missing or invalid value for required property: ${prop}`);
    //   }
    // }

    super(txTimeStamp);
    this.tags = tags;
    this.txID = txID;
    this.type = this.getType();
    this.version = this.getVersion();
    try {
      // this.productionID = jsonContent.productionID;
      // this.id = jsonContent.id;
      // this.name = jsonContent.name;
      // this.activityCode = jsonContent.activityCode;
      // // this.account = jsonContent.account;
      // // this.facility = jsonContent.facility;
      // // this.facilityFunction = jsonContent.facilityFunction;
      // // this.location = jsonContent.location;
      // // this.equipment = jsonContent.equipment;
      // this.subject = jsonContent.subject;
      // this.predecessorID = jsonContent.predecessorID;
      // this.input = jsonContent.input;
      // this.inputQuantity = jsonContent.inputQuantity;
      // this.output = jsonContent.output;
      // this.outputQuantity = jsonContent.outputQuantity;
      // this.outputUnit = jsonContent.outputUnit;
      // //this.resources = jsonContent.resources;
      // this.actualStart = jsonContent.actualStart;
      // this.actualEnd = jsonContent.actualEnd;
      // this.actualDuration = jsonContent.actualDuration;
      // this.actualDurationUnit = jsonContent.actualDurationUnit;
      // this.activityStatus = jsonContent.activityStatus;
      // // this.statusReason = jsonContent.statusReason;
      // // this.timestamp = jsonContent.timestamp; 
      // this.sector = jsonContent.sector; 
      // this.client  = jsonContent.client;
      // this.documentNumber = jsonContent.documentNumber;  




      this.name = jsonContent.name;
      this.input = jsonContent.input;
      this.inputQuantity = jsonContent.inputQuantity;
      this.output = jsonContent.output;
      this.outputQuantity = jsonContent.outputQuantity;
      this.outputUnit = jsonContent.outputUnit;
      this.inputPackaging = jsonContent.inputPackaging;
      this.inputPackagingQuantity = jsonContent.inputPackagingQuantity;
      this.date = jsonContent.date;
      this.producer = jsonContent.producer;
      this.producerCode = jsonContent.producerCode;
      this.sector = jsonContent.sector;
      this.client  = jsonContent.client;
      this.documentNumber = jsonContent.documentNumber;  
      this.predecessorID = jsonContent.predecessorID;
      this.activityCode = jsonContent.activityCode;
      this.id = jsonContent.id;





    } catch (err) {
      throw new Error('Activity not well formed:' + err);
    }
  }

  // Use the generic toJsonString method from BusinessObject
  toJsonString() {
    // Create an object based on the desired fields
    const jsonObject = {
      // productionID: this.productionID,
      // id: this.id,
      // name: this.name,
      // activityCode: this.activityCode,
      // // account: this.account,
      // // facility: this.facility,
      // // facilityFunction: this.facilityFunction,
      // // location: this.location,
      // // equipment: this.equipment,
      // // subject: this.subject,
      // predecessorID: this.predecessorID,
      // input: this.input,
      // inputQuantity: this.inputQuantity,
      // output: this.output,
      // outputQuantity: this.inputQuantity,
      // outputUnit: this.outputUnit,
      // //resources: this.resources,
      // actualStart: this.actualStart,
      // actualEnd: this.actualEnd,
      // actualDuration: this.actualDuration,
      // actualDurationUnit: this.actualDurationUnit,
      // // activityStatus: this.activityStatus,
      // // statusReason: this.statusReason,
      // timestamp : this.timestamp,
      // type: this.getType(),
      // version: this.getVersion(),
      // sector: this.sector,
      // client: this.client,
      // documentNumber: this.documentNumber



       
     
      name: this.name,
      activityCode: this.activityCode,
      input: this.input,
      inputQuantity: this.inputQuantity,
      inputPackaging: this.inputPackaging,
      inputPackagingQuantity: this.inputPackagingQuantity,
      output: this.output,
      outputQuantity: this.inputQuantity,
      outputUnit: this.outputUnit,
      
      sector: this.sector,
      date: this.date,
      client: this.client,
      clientCode: this.clientCode,
      documentNumber: this.documentNumber,

      timestamp : this.timestamp,
      type: this.getType(),
      version: this.getVersion(),
     
     
      id: this.id,
      predecessorID: this.predecessorID,
    };

    // Use the generic toJsonString method
    console.log("***************************** Activity ", jsonObject);
    var data = JSON.stringify(jsonObject, null, 2);
    console.log("***************************** Activity ", data);
    return data;
  }

  getType() {
    return "Activity";
  }

  getVersion() {
    return "1.0.0";
  }

  getTags() {
    return this.tags;
  }
}

//module.exports = Activity_1_0_0;
 
export default Activity_1_0_0;