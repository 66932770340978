import React, { useState, useEffect } from 'react';

import { Container, Row, Col, Button, Card, Form } from 'react-bootstrap'
import { Link, withRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { NotificationContainer, NotificationManager } from 'react-notifications';

import ClassFactory from '../../trace-rice/classes/ClassFactory';

const Search = ({ location }) => {

  const seconds = 5000
  const [loading, setLoading] = useState(false);

  const [newSearch, setNewSearch] = useState(false);
  useEffect(() => {
    // Reset toggle states to all false when initiating a new search
    setToggleStates(Array(searchDataResult.length).fill(false));
    setNewSearch(false);
  }, [inputValue, traceableObjectTypes, flowDirection, searchDataResult, newSearch]);


  const [inputValue, setInputValue] = useState('');
  const [searchDataResult, setSearchDataResult] = useState([]);
  const [gapsResult, setGapsResult] = useState([]);
  const [flowDirection, setFlowDirection] = useState({
    upstream: true,
    downstream: true,
  });
  const [traceableObjectTypes, setTraceableObjectTypes] = useState({
    asset: true,
    activity: true,
    event: true,
  });

  const [toggleStates, setToggleStates] = useState(Array(searchDataResult.length).fill(false));
  const history = createBrowserHistory();

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleCheckboxChange = (category, key) => {
    if (category === 'flowDirection') {
      setFlowDirection((prev) => ({ ...prev, [key]: !prev[key] }));
    } else if (category === 'traceableObjectTypes') {
      setTraceableObjectTypes((prev) => ({ ...prev, [key]: !prev[key] }));
    }
  };

  const handleSubmit = async () => {
    NotificationManager.removeAll();
    setLoading(true);

    setNewSearch(true);
    setSearchDataResult([]);
    setGapsResult([]);

    if (inputValue.length <= 0) {
      NotificationManager.warning("Enter Batch ID", 'Warning', seconds);
      setLoading(false);
      return;
    }

    const isAtLeastOneTrue = Object.values(traceableObjectTypes).some(value => value === true);

    if (!isAtLeastOneTrue) {
      NotificationManager.warning("Select at least one Traceable Object Type", 'Warning', seconds);
      setLoading(false);
      return;
    }

    const cf = new ClassFactory();

    let data;

    if (flowDirection.downstream == false && flowDirection.upstream == false) {
      data = await cf.searchData(inputValue, traceableObjectTypes);

    }
    else if (flowDirection.downstream == true && flowDirection.upstream == false) {

      data = await cf.searchDownstream(inputValue, traceableObjectTypes);

      if (data) {
        data = cf.sortBusinessObjects(data);
      }
    }
    else if (flowDirection.downstream == false && flowDirection.upstream == true) {
      data = await cf.searchUpstream(inputValue, traceableObjectTypes);

      if (data) {
        data = cf.sortBusinessObjects(data);
      }
      //data = cf.sortBusinessObjects(objects);
    }
    else {
      //let visitedObjects = new Set();

      data = await cf.searchUpstream(inputValue, traceableObjectTypes);
      var obj2 = await cf.searchDownstream(inputValue, traceableObjectTypes);
      for (var i = 0; i < obj2.length; i++) {
        data.push(obj2[i]);
      }

      if (traceableObjectTypes.activity == true && traceableObjectTypes.event == true && traceableObjectTypes.asset == true)
          data = cf.sortBusinessObjects(data)
      else{
          data = cf.getUnique(data);
      }     
    }
    setLoading(false);

    if (data.toString().toLowerCase().startsWith("error")) {
      NotificationManager.error(data, 'Warning');
      setSearchDataResult([]);
      return;
    } else if (data.length === 0) {
      NotificationManager.warning("No results found!", 'Warning', seconds);
      setSearchDataResult([]);
      setLoading(false);
      return;
    } else {
      setSearchDataResult(data);
    }

    setLoading(false);
  };

 
  const handleValidateGaps = async () => {
    setGapsResult([]);

    if (inputValue.length <= 0) {
      NotificationManager.warning("Enter Batch ID", 'Warning');
      return;
    }

    const isAllCheckboxesChecked =
      flowDirection.upstream &&
      flowDirection.downstream &&
      traceableObjectTypes.activity &&
      traceableObjectTypes.event;

    if (!isAllCheckboxesChecked) {
      NotificationManager.warning("Select all checkboxes to Validate Gaps", 'Warning');
      return;
    }

    //const data = await validateGaps(inputValue);

    const cf = new ClassFactory();

    if (searchDataResult.length > 0) {
      const finalData = await cf.findMissingGaps(searchDataResult);

      if (finalData.length > 0) {
        setGapsResult(finalData);
      }
      else {
        NotificationManager.success("No missing information", 'Information');
      }

    }
    else {
      NotificationManager.success("No missing information", 'Information');
    }
  };

  const handleCardClick = (index, event) => {

    setToggleStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = !newStates[index];
      return newStates;
    });
    const newHash = `#${index}`;
    if (location.hash === newHash) {
      const element = document.getElementById(index.toString());
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      history.push(`#${index}`);
    }
  };



  return (<>
    <div style={{ paddingTop: "100px" }}>

      <div style={{
        border: "2px solid #ccc",
        borderRadius: "8px",
        padding: "20px",
        margin: "20px",
        backgroundColor: "#f9f9f9",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        maxWidth: "600px",
        margin: "auto"
      }}>
        <Container>

          <NotificationContainer />

          <Row className="justify-content-md-center">
            <Col xs={12} md="auto">
              <label>Search by Batch ID: </label>
              <input
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                placeholder="Enter Batch ID here"
              />
            </Col>
            <Col xs={12} md="auto">
              <Button onClick={handleSubmit}  disabled={loading}> {loading ? 'Searching...' : 'Search'}</Button>
            </Col>
             
          </Row>

        </Container>
        <Container style={{ paddingTop: "30px" }}>

          <Row>
            <Col>
              <h4>Flow Direction</h4>
              <hr style={{ color: "#CCCCCC" }} />
            </Col>
            <Col>
              <h4>Traceable Object Type</h4>
              <hr style={{ color: "#CCCCCC" }} />
            </Col>
          </Row>
          <Row>
            <Col>


              <ul className="checkboxes-list">

                <li >
                  <div className="checkboxes-list-item">
                    <div className="left-section">
                      <input
                        type="checkbox"
                        id="upstreamCheckbox"
                        checked={flowDirection.upstream}
                        onChange={() => handleCheckboxChange('flowDirection', 'upstream')}
                      /> {/* Using non-breaking space */}
                      &nbsp;
                      <label>Upstream</label>
                    </div>
                  </div>
                </li>
                <li >
                  <div className="checkboxes-list-item">
                    <div className="left-section">
                      <input
                        type="checkbox"
                        id="downstreamCheckbox"
                        checked={flowDirection.downstream}
                        onChange={() => handleCheckboxChange('flowDirection', 'downstream')}
                      /> {/* Using non-breaking space */}
                      &nbsp;
                      <label>Downstream</label>
                    </div>
                  </div>
                </li>
              </ul>
            </Col>
            <Col>
              <ul className="checkboxes-list">

                <li >
                  <div className="checkboxes-list-item">
                    <div className="left-section">
                      <input
                        type="checkbox"
                        id="assetCheckbox"
                        checked={traceableObjectTypes.asset}
                        onChange={() => handleCheckboxChange('traceableObjectTypes', 'asset')}
                      /> {/* Using non-breaking space */}
                      &nbsp;
                      <label >Asset</label>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="checkboxes-list-item">
                    <div className="left-section">
                      <input
                        type="checkbox"
                        id="activityCheckbox"
                        checked={traceableObjectTypes.activity}
                        onChange={() => handleCheckboxChange('traceableObjectTypes', 'activity')}
                      /> {/* Using non-breaking space */}
                      &nbsp;
                      <label>Activity</label>
                    </div>
                  </div>
                </li>
                <li >
                  <div className="checkboxes-list-item">
                    <div className="left-section">
                      <input
                        type="checkbox"
                        id="eventCheckbox"
                        checked={traceableObjectTypes.event}
                        onChange={() => handleCheckboxChange('traceableObjectTypes', 'event')}
                      /> {/* Using non-breaking space */}
                      &nbsp;
                      <label >Event</label>
                    </div>
                  </div>
                </li>
              </ul>
            </Col>
          </Row>
          <div style={{ backgroundColor: "#EEEEEE", maxWidth: "50%", textAlign: "center", alignContent: "center" }}>
          </div>
        </Container>
      </div>


      {(searchDataResult !== null && searchDataResult !== "" && searchDataResult.length > 0) &&
        <Container style={{ paddingTop: "10px", paddingBottom:"100px", textAlign: "center" }} className="justify-content-md-center">

          <div style={{ paddingTop: "10px" }}>
            <Row >
              <Col xs lg="12" style={{ textAlign: "center;" }}>
                <Button onClick={handleValidateGaps}>Validate Gap Activities</Button>

              </Col>
            </Row>
            <Row>
              <Col xs lg="12" style={{ textAlign: "center;" }}>
                {(gapsResult !== null && gapsResult !== "" && gapsResult.length > 0) &&
                  <div style={{ paddingTop: "10px" }} >


                    <div style={{ paddingTop: "10px", backgroundColor: "#eeeeee" }} className="justify-content-md-center">
                      <ul>
                        {gapsResult.map((errorMessage, index) => (
                          <li key={errorMessage}>{errorMessage}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                }
              </Col>
            </Row>


          </div>

          <div style={{ paddingTop: "10px" }}>
            {searchDataResult.map((item, index) => (
              <React.Fragment key={item.id}>
                <Card className="mb-3" style={{ margin: '10px', backgroundColor: index % 2 === 0 ? '#f0f0f0' : 'white' }}>

                  <Card.Body>
                    <Card.Title style={{ textAlign: "center" }}>

                      {item !== null &&
                        item.hasOwnProperty(
                          'activityCode'
                        ) ? (
                        <Link
                          to={`#${index}`}
                          onClick={() => handleCardClick(index)}
                        >
                          {item.activityCode} -{' '}
                          {item.type}
                        </Link>
                      ) : (
                        <p>
                          <Link
                            to={`#${index}`}
                            onClick={() => handleCardClick(index)}
                          >
                            {item.type}
                          </Link>
                        </p>
                      )}


                    </Card.Title>
                    <Card.Text>

                      <hr />
                      {toggleStates[index] ? (
                        <pre className='pre' style={{ textAlign: "left" }}>
                          {JSON.stringify(item, null, 2)}

                        </pre>
                      ) : (
                        <div>
                          {JSON.stringify(item, null, 2)}
                        </div>
                      )}
                      <hr />
                      <div style={{ paddingTop: "10px" }}>

                        <b>
                          Blockchain Transaction ID:
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={process.env.REACT_APP_URL2 + `?tab=transactions&transId=${item.txID}`}> {item.txID}</a>
                        </b>
                      </div>

                    </Card.Text>
                  </Card.Body>
                </Card>
              </React.Fragment>
            ))}
          </div>
        </Container>
      }


    </div>

  </>
  );
};

export default withRouter(Search);
