// LogoComponent.js

import React, { useState, useEffect } from 'react';
const LogoComponent = () => {
  const [config, setConfig] = useState(null);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await fetch('siteConfig.json'); // Adjust the path accordingly
        const data = await response.json();

        setConfig(data);
      } catch (error) {
        console.error('Error fetching config:', error);
      }
    };

    fetchConfig();
  }, []); // Empty dependency array means this effect runs once after the initial render

  if (!config) {
    // Loading state or handle error
    return null;
  }

  const logoUrl = config.logoUrl;
 
  return (
      <img  style={{
        width: 120,
        '@media (max-width: 1415px) and (min-width: 990px)': {
          width: 120,
          // height: 40
        },
      }} src={logoUrl} alt="Logo" />
  );
};

export default LogoComponent;
