import FunctionsModels from './../classes/FunctionsModels';
const { Activity, FunctionWithActivities, Process } = FunctionsModels;

export const readAndParseJson = async () => {
  try {
    const fileName = "functions.json";
    const response = await fetch('functions.json');

    if (response.ok) {
      const fileContent = await response.json();

      // Assuming 'processes' is the array containing process information
      const processes = fileContent.processes.map(processData => new Process(processData));

      return processes;
    } else {
      throw new Error(`Failed to fetch functions.json: ${response.status} ${response.statusText}`);
    }

  } catch (error) {
    throw new Error(`Error reading or parsing JSON file: ${error.message}`);
  }
};
