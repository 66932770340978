class BusinessObject {
  constructor(txTimeStamp) {
    this.txTimeStamp = txTimeStamp;
  }
 

  toJsonString() {
    throw new Error('Method toJsonString must be implemented by subclasses');
  }

  getType() {
    throw new Error('Method getType must be implemented by subclasses');
  }

  getVersion() {
    throw new Error('Method getVersion must be implemented by subclasses');
  }

  getTags() {
    throw new Error('Method getTags must be implemented by subclasses');
  }
}
export default BusinessObject;
//module.exports = BusinessObject;
