const fs2 = require('fs');

class Activity {
  constructor(activityData) {
    this.code = activityData.code;
    this.name = activityData.name;
    this.predecessor = activityData.predecessor;
    this.inputType = activityData.inputType;
    this.outputType = activityData.outputType;
  }
}

class FunctionWithActivities {
  constructor(functionData) {
    this.name = functionData.name;
    this.activities = functionData.activities.map(activityData => new Activity(activityData));
  }
}

class Process {
  constructor(processData) {
    this.processName = processData.name;
    this.functions = processData.functions.map(functionData => new FunctionWithActivities(functionData));
  }
}
export default {
  Activity,
  FunctionWithActivities, 
  Process
};
