import axios from "axios";

// Function to make a login request
export const loginUser = async  (username, password) => {
	try {
		const response = await axios.post(`${process.env.REACT_APP_API_URL}api/login`, {
			username: username,
			password: password,
		}, {
			timeout: 10000, // Set the timeout to 5 seconds (adjust as needed)
		  });

		// The token will be available in response.data.token
		const token = response.data.token;
		localStorage.setItem('authToken', token);
	} catch (error) {

		if (axios.isCancel(error)) {
			return "Error: Request canceled";
		  } else if (error.code === 'ECONNABORTED') {
			return "Error: Request timed out";
		}
		else {
			// Handle login error
			console.error('Login failed:', error.response?.data.message || 'Unknown error');
			return "Error: " + error.message;
		}
	}
}

export const search = async (search) => {
	let token = localStorage.getItem('authToken');
  
	try {
	  const response = await axios.post(
		`${process.env.REACT_APP_API_URL}api/search`,
		{
		  batchId: search,
		},
		{
		  headers: {
			Authorization: `Bearer ${token}`,
		  },
		  timeout: 10000, // Set the timeout to 5 seconds (adjust as needed)
		}
	  );
  
	  return response.data;
	} catch (error) {
	  if (axios.isCancel(error)) {
		return "Error: Request canceled";
	  } else if (error.code === 'ECONNABORTED') {
		return "Error: Request timed out";
	  } else {
		if (error.response && error.response.status === 401) {
		  return "Error: " + error.message + ". Please logout and login again";
		}
  
		console.error('Error:', error.message);
		return "Error: " + error.message;
	  }
	}
  };
  

export const searchAndSort = async (search) => {
	let token = localStorage.getItem('authToken');

	try {
		const response = await axios.post(`${process.env.REACT_APP_API_URL}api/searchAndSort`, search, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
			timeout: 10000,
		});

		return response.data;
	} catch (error) {

		if (axios.isCancel(error)) {
			return "Error: Request canceled";
		  } else if (error.code === 'ECONNABORTED') {
			return "Error: Request timed out";
		}
		else {

			if (error.response && error.response.status === 401) {
				return "Error: " + error.message + ". Please logout and login again";
			}

			console.error('Error:', error.message);
			return "Error: " + error.message;
		}
	}
};


export const uploadFile = async (data) => {
	let token = localStorage.getItem('authToken');

	if (token == null || token === undefined) {
		await loginUser();
		token = localStorage.getItem('authToken');
	}

	try {
		const response = await axios.post(`${process.env.REACT_APP_API_URL}api/uploadFileObjects`, data, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
			timeout: 10000
		});

		return response.data;
	} catch (error) {

		if (axios.isCancel(error)) {
			return "Error: Request canceled";
		  } else if (error.code === 'ECONNABORTED') {
			return "Error: Request timed out";
		}
		if (error.response && error.response.status === 401) {
			return "Error: " + error.message + ". Please logout and login again";
		}
		
			if (error.response.data) {
				if (Array.isArray(error.response.data)) {
					const resultString = "Error: " + error.response.data.join(';');
					return resultString;
				}

				else {
					return "Error: " + error.response.data;
				}
			}
			else {
				return "Error: " + error.message;
			}
		
	}
};
