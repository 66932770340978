//const BusinessObject = require('./BusinessObject');
import BusinessObject from './BusinessObject';
class Event_1_0_0 extends BusinessObject {
  constructor(tags, jsonContent, txID, txTimeStamp) {
    super(txTimeStamp);

    this.tags = tags;
    this.txID = txID;
    this.type = this.getType();
    this.version = this.getVersion();
    try {
      this.productionID = jsonContent.productionID;
      this.id = jsonContent.id;
      this.name = jsonContent.name;
      this.batchID = jsonContent.batchID;

      this.date = jsonContent.date;
      this.processInstance = jsonContent.processInstance;
      this.timestamp = jsonContent.timestamp;

      // Check if 'attributes' field exists in jsonContent
      if (jsonContent.attributes && Array.isArray(jsonContent.attributes)) {
        // Iterate over each attribute and add it to the instance
        jsonContent.attributes.forEach(attribute => {
          const { attribute: attributeName, name, value } = attribute;
          this[attributeName] = { name, value };
        });
      }


    } catch (err) {
      throw new Error('Event not well-formed:' + err);
    }
  }

  toJsonString() {
    const jsonObject = {
      productionID: this.productionID,
      id: this.id,
      name: this.name,
      batchID: this.batchID,
      timestamp: this.timestamp,
      type: this.getType(),
      version: this.getVersion(),
      attributes: Object.keys(this)
      .filter(key => key !== 'tags' && key !== 'txID' && key !== 'type' && key !== 'version')
      .map(attributeName => ({
        attribute: attributeName,
        name: this[attributeName].name,
        value: this[attributeName].value,
      })),
      
    };

    return JSON.stringify(jsonObject, null, 2);
  }

  getType() {
    return "Event";
  }

  getVersion() {
    return "1.0.0";
  }

  getTags() {
    return this.tags;
  }
}

//module.exports = Event_1_0_0;
 

export default Event_1_0_0;