import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import createStore from './state/store';
import authOperations from './state/redux/auth/operations';
import Theme from './components/Theme';
import App from './components/App';
import { unregister } from './registerServiceWorker';

// Fetch the configuration file
fetch('siteConfig.json') // Update the path accordingly
  .then(response => response.json())
  .then(config => {
    // Dynamically set the app title

	console.log(config.title);
    document.title = config.title || 'Default Title';

    // Continue with the rest of your rendering logic
    const mode = localStorage.getItem('theme-mode') || 'light';
    const store = createStore({ theme: { mode } });

    store.subscribe(themeSideEffect(store));

    function themeSideEffect(storeObj) {
      let theme;
      return () => {
        const state = storeObj.getState();
        if (theme !== state.theme) {
          theme = state.theme;
          localStorage.setItem('theme-mode', theme.mode);
        }
      };
    }

    store.dispatch(authOperations.network());

    unregister();

    ReactDOM.render(
      <Provider store={store}>
        <Theme>
          <App />
        </Theme>
      </Provider>,
      document.getElementById('root')
    );
  })
  .catch(error => {
    console.error('Error fetching config:', error);

    // Continue with rendering, possibly with a default title
    const mode = localStorage.getItem('theme-mode') || 'light';
    const store = createStore({ theme: { mode } });

    store.subscribe(themeSideEffect(store));

    function themeSideEffect(storeObj) {
      let theme;
      return () => {
        const state = storeObj.getState();
        if (theme !== state.theme) {
          theme = state.theme;
          localStorage.setItem('theme-mode', theme.mode);
        }
      };
    }

    store.dispatch(authOperations.network());

    unregister();

    ReactDOM.render(
      <Provider store={store}>
        <Theme>
          <App />
        </Theme>
      </Provider>,
      document.getElementById('root')
    );
  });
