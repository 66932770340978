import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import clientJson from '../../../package.json';
import FabricVersion from '../../FabricVersion';
const styles = theme => {
  const { type } = theme.palette;
  const dark = type === 'dark';
  return {
    root: {
      margin: '2%'
    },
    footer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '10px',
      backgroundColor: dark ? '#5e558e' : '#e8e8e8',
      color: dark ? '#ffffff' : undefined,
      textAlign: 'center',
      position: 'fixed',
      left: 0,
      right: 0,
      bottom: 0,
    },
    footerText:
    {
       
      paddingTop:'0px'
    },
    footerLogo: {
      maxWidth: '100%',
      height: 'auto',
      marginRight: '10px', // Adjust as needed
    },
    // Media query for smaller screens
    [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
      footer: {
        flexDirection: 'column', // Stack items vertically on smaller screens
      },
      footerLogo: {
        margin: '0', // Remove margin for smaller screens
        marginBottom: '10px', // Add margin to the bottom for spacing
      },
    },
  };
};

const FooterView = ({ classes }) => {
  const [config, setConfig] = useState({});

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await fetch('/siteConfig.json'); // Update the path accordingly
        const data = await response.json();
        setConfig(data);
      } catch (error) {
        console.error('Error fetching config:', error);
      }
    };

    fetchConfig();
  }, []); // Empty dependency array to run the effect only once

  return (
    <><div className={classes.root}>
        <div className={classes.footer}>
        <img src="/BARRA_2024.png" style={{maxWidth:"300px"}}  className={classes.footerLogo} /> &nbsp;&nbsp;|&nbsp;&nbsp; 2024 ©  Bitcliq Technologies, S.A. 
        </div> 
    </div> 
      
      </>

  );
};

export default withStyles(styles)(FooterView);
