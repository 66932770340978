
import React, { useState, useEffect } from 'react';

import { NotificationContainer, NotificationManager } from 'react-notifications';
import { Container, Row, Col, Button } from 'react-bootstrap'
import 'react-notifications/lib/notifications.css';
import 'bootstrap/dist/css/bootstrap.min.css'

import { uploadFile } from '../../trace-rice/api/api.js';
function Upload() {
    const [loading, setLoading] = useState(false);
    const seconds = 5000

    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileUpload = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleUpload = async () => {
        setLoading(true);
        NotificationManager.removeAll();
        if (selectedFile) {
            try {
                const formData = new FormData();
                formData.append('file', selectedFile);
                var data = await uploadFile(formData);
                if (data === "Ok") {
                    NotificationManager.success("File uploaded successfully", 'Information', seconds);
                }
                else {
                    NotificationManager.warning(data, 'Warning', seconds);
                }

            }
            catch (error) {
                NotificationManager.error(error.message, 'Error', seconds);
            }

        }
        else {
            NotificationManager.warning("Please select a file", 'Warning', seconds);
        }
        setLoading(false);
    };

    return (
        <>
            <div style={{ paddingTop: "100px" }}>

                <div style={{
                    border: "2px solid #ccc",
                    borderRadius: "8px",
                    padding: "20px",
                    margin: "20px",
                    backgroundColor: "#f9f9f9",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    maxWidth: "600px",
                    margin: "auto"
                }}>
                    <Container>

                        <Row className="justify-content-md-center">
                            <Col xs={12} md="auto">
                                <label>Upload File:</label>

                            </Col>
                            <Col xs={12} md="auto">
                                <input type="file" onChange={handleFileUpload} />
                            </Col>
                            <Col xs={12} md="auto">
                                <Button  disabled={loading} onClick={handleUpload}>{loading ? 'Uploading...' : 'Upload'}</Button>
                            </Col>
                        </Row>
                        <NotificationContainer />
                    </Container>
                </div>
            </div>
        </>
    );
}

export default Upload;



